.productcard {
  box-sizing: border-box;
  margin: 1rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.productcard > img {
  height: 100%;
  height: calc(100%);
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 0.5rem;
}

.productcard-title-fade {
  position: absolute;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(254, 254, 254, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 1;
}

.productcard-rank {
  font-size: 2.25rem;
}

.productcard-data-row {
  font-size: 1.8rem;
  word-break: keep-all;
}

.productcard-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #707070;
  height: 5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: start;
}

@media only screen and (min-width: 299px) {
  .productcard-rank {
    font-size: 1rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 524px) {
  .productcard-rank {
    font-size: 1.8rem;
  }

  .productcard-data-row {
    font-size: 1.4rem;
  }
  .productcard-title {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 525px) and (max-width: 1049px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1149px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1150px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.6rem;
  }
  .productcard-title {
    font-size: 1.6rem;
  }
}
