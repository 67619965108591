.pricetable {
  color: white;
  width: 100%;
}

.pricetable .row {
  box-sizing: border-box;
  border-bottom: 1px solid white;
  padding: 0.75rem 2rem;
}

.pricetable .row:last-child {
  border-bottom: none;
}

.pricetable .col-xs-10 {
  text-align: start;
}

.pricetable .col-xs-2 {
  text-align: end;
}
