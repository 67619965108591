.authmodal-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
}

.authmodal-body {
  margin: unset;
  background-color: white;
  width: 100%;
  max-width: unset;
  background-color: white;
  border-radius: 0;
  padding: 2rem;
  padding-top: 3rem;
  box-sizing: border-box;
  outline: none;
}

@media only screen and (min-width: 400px) {
  .authmodal-body {
    margin: auto;
    width: 90%;
    max-width: 400px;
    border-radius: 1rem;
    padding: 3rem;
  }
}
