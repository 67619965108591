.modal-body {
  z-index: 1100;
  width: 90%;
  max-width: 500px;
  margin: auto;
  background-color: white;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 2rem;
  outline: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  transition: ease-in-out 0.25s;
  opacity: 1;
  z-index: 1099;
  display: flex;
}
