.controller-container {
  padding-bottom: 0.5rem;
}

.controller-col {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.controller-switch-container {
  background-color: #e8e8e8;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
  height: 4rem;
  font-size: 1.6rem;
}
