@import '~normalize.css';
@import url('https://use.typekit.net/rva4lfj.css');

:root {
  --color-icon-gray: #606060;
  --color-black: #101010;
  --color-primary: #ff2f55;
  --color-primary-dark: #e2294b;
  --color-lightGrey: #f5f5f5;
  --color-grey: #dcdcdc;
  --color-darkGrey: #b0b0b0;
  --color-success: #ddf0e8;
  --color-success-text: #0c8150;
  --color-warning: #f9ece0;
  --color-warning-text: #c07529;
  --color-error: #fae1e2;
  --color-error-text: #c23030;
  --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
  --font-family: museo-sans, sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  line-height: 1.6;
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 300;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.85em;
}

p {
  margin: 0;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='color']):not([type='button']):not([type='reset']):focus,
select:focus,
textarea:focus,
textarea[type='text']:focus,
button:focus {
  outline: none;
}

button,
.button {
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: white;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  border-radius: 1rem;
  margin: 0;
  cursor: pointer;
  transition: ease-in-out 0.15s;
}

button:hover,
.button:hover {
  background-color: var(--color-primary-dark);
}

a {
  color: var(--color-primary);
}

input:disabled,
button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.button-md {
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.9rem;
}

.button-lg {
  font-weight: 700;
  font-size: 2rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.button-small {
  margin: 0;
  padding: 0.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.9rem;
}

.button-miny {
  margin: 0;
  padding: 0.125rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  font-size: 1.2rem;
  border-radius: 0.75rem;
}

.button-wide {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 42px;
}

.button-gray {
  color: var(--color-black);
  background-color: var(--color-grey);
}

.button-gray:hover {
  opacity: 1;
  color: var(--color-black);
  background-color: var(--color-darkGrey);
}

.button-transparent {
  color: white;
  background-color: rgb(255, 255, 255, 0.3);
}

.button-transparent:hover {
  background-color: rgb(255, 255, 255, 0.4);
}

.button-icon {
  color: var(--color-icon-gray);
  background: none;
  padding: 0.5rem;
  border-radius: 0;
}

.button-icon:hover {
  background-color: transparent;
  color: #888888;
}

.button-social {
  width: 100%;
  height: 42px;
  margin: 0;
  margin-top: 1rem;
}

.button-social-facebook {
  background-color: #4767b2;
}

.button-social-amazon {
  background-color: #ff9900;
}

.button-social-facebook:hover {
  background-color: #4767b2;
  opacity: 0.8;
}

.button-social-amazon:hover {
  background-color: #ff9900;
  opacity: 0.8;
}

.gradient-fill {
  color: white;
  background: -webkit-linear-gradient(left, #f25e6c, #b166b9);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f25e6c),
    to(#b166b9)
  );
  background: -o-linear-gradient(left, #f25e6c 0, #b166b9 100%);
  background: linear-gradient(90deg, #f25e6c 0, #b166b9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f25e6c",endColorstr="#b166b9",GradientType=1);
}

.swiper-container {
  margin-left: -1rem;
  margin-right: -1rem;
}

textarea,
input,
.input {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 300;
  font-family: inherit;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1rem;
  margin: 0;
  box-sizing: border-box;
  border: 2px solid var(--siderbar-border-color);
}

.col-xs-hide {
  display: none;
}

.col-xs-show {
  display: block;
}

@media only screen and (min-width: 48em) {
  .col-sm-hide {
    display: none;
  }
  .col-sm-show {
    display: block;
  }
}

@media only screen and (min-width: 64em) {
  .col-md-hide {
    display: none;
  }
  .col-md-show {
    display: block;
  }
}

@media only screen and (min-width: 75em) {
  .col-lg-hide {
    display: none;
  }
  .col-lg-show {
    display: block;
  }
}

span.line {
  display: inline-block;
}
