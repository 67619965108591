.nichecard-container {
  margin: 1rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.nichecard {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 1rem;
}

.nichecard-img {
  width: 100%;
  border-radius: 1rem;
}

.nichecard-rank {
  font-size: 2.25rem;
}

.nichecard-data-row {
  font-size: 1.8rem;
  word-break: keep-all;
}

@media only screen and (min-width: 299px) {
  .nichecard-data-row {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 300px) {
  .nichecard-data-row {
    font-size: 1.8rem;
  }
}
