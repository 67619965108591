.callout {
  box-sizing: border-box;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.callout-primary {
  color: white;
  background-color: var(--color-primary);
}

.callout-info {
  color: var(--color-black);
  background-color: var(--color-grey);
}

.callout-warning {
  color: var(--color-warning-text);
  background-color: var(--color-warning);
}

.callout-success {
  color: var(--color-success-text);
  background-color: var(--color-success);
}

.callout-error {
  color: var(--color-error-text);
  background-color: var(--color-error);
}

.callout p {
  margin: 0;
}
