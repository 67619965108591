.menu-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.menu-item > svg {
  color: var(--color-icon-gray);
}
