.timer {
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1rem;
  margin: 0;
  margin-top: 0.75rem;
}

.timer span {
  width: 100%;
  display: block;
}

.timer .time {
  font-weight: 500;
  font-size: 1.3rem;
}

.timer-last {
  background-color: var(--color-grey);
}

.timer-next {
  color: white;
  background-color: var(--color-primary);
}
