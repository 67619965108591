.gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5);
  background-size: 400% 400%;
  animation: gradient 30s ease-in-out infinite;
  color: white;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
