.sidebar-menu {
  position: fixed;
  overflow-x: hidden;
  width: auto;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transition: ease-in-out 0.25s;
  background-color: var(--sidebar-color);
  border-right: 1px solid var(--siderbar-border-color);
  box-sizing: border-box;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  transition: ease-in-out 0.25s;
  opacity: 0;
}

.sidebar-content {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
