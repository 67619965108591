.header {
  background-color: white;
  box-sizing: border-box;
  border-bottom: 1px solid var(--siderbar-border-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(0);
  transition: transform 0.3s ease;
  padding: 0.5rem 0;
}

.header.hidden {
  transform: translateY(-100%);
}

.header-title {
  font-weight: 700;
  font-size: 2.7rem;
  transition: all ease-in-out 0.25s;
  color: black;
  text-decoration: none;
}
