@import url(https://use.typekit.net/rva4lfj.css);
:root {
  --color-icon-gray: #606060;
  --color-black: #101010;
  --color-primary: #ff2f55;
  --color-primary-dark: #e2294b;
  --color-lightGrey: #f5f5f5;
  --color-grey: #dcdcdc;
  --color-darkGrey: #b0b0b0;
  --color-success: #ddf0e8;
  --color-success-text: #0c8150;
  --color-warning: #f9ece0;
  --color-warning-text: #c07529;
  --color-error: #fae1e2;
  --color-error-text: #c23030;
  --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
  --font-family: museo-sans, sans-serif;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  line-height: 1.6;
  font-size: 1.6rem;
  font-size: var(--font-size);
  font-family: museo-sans, sans-serif;
  font-family: var(--font-family);
  font-weight: 300;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.85em;
}

p {
  margin: 0;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='color']):not([type='button']):not([type='reset']):focus,
select:focus,
textarea:focus,
textarea[type='text']:focus,
button:focus {
  outline: none;
}

button,
.button {
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: white;
  background-color: #ff2f55;
  background-color: var(--color-primary);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 1rem;
  margin: 0;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
}

button:hover,
.button:hover {
  background-color: #e2294b;
  background-color: var(--color-primary-dark);
}

a {
  color: #ff2f55;
  color: var(--color-primary);
}

input:disabled,
button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.button-md {
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.9rem;
}

.button-lg {
  font-weight: 700;
  font-size: 2rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.button-small {
  margin: 0;
  padding: 0.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.9rem;
}

.button-miny {
  margin: 0;
  padding: 0.125rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  font-size: 1.2rem;
  border-radius: 0.75rem;
}

.button-wide {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 42px;
}

.button-gray {
  color: #101010;
  color: var(--color-black);
  background-color: #dcdcdc;
  background-color: var(--color-grey);
}

.button-gray:hover {
  opacity: 1;
  color: #101010;
  color: var(--color-black);
  background-color: #b0b0b0;
  background-color: var(--color-darkGrey);
}

.button-transparent {
  color: white;
  background-color: rgb(255, 255, 255, 0.3);
}

.button-transparent:hover {
  background-color: rgb(255, 255, 255, 0.4);
}

.button-icon {
  color: #606060;
  color: var(--color-icon-gray);
  background: none;
  padding: 0.5rem;
  border-radius: 0;
}

.button-icon:hover {
  background-color: transparent;
  color: #888888;
}

.button-social {
  width: 100%;
  height: 42px;
  margin: 0;
  margin-top: 1rem;
}

.button-social-facebook {
  background-color: #4767b2;
}

.button-social-amazon {
  background-color: #ff9900;
}

.button-social-facebook:hover {
  background-color: #4767b2;
  opacity: 0.8;
}

.button-social-amazon:hover {
  background-color: #ff9900;
  opacity: 0.8;
}

.gradient-fill {
  color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #f25e6c), to(#b166b9));
  background: linear-gradient(90deg, #f25e6c 0, #b166b9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f25e6c",endColorstr="#b166b9",GradientType=1);
}

.swiper-container {
  margin-left: -1rem;
  margin-right: -1rem;
}

textarea,
input,
.input {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 300;
  font-family: inherit;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1rem;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid var(--siderbar-border-color);
}

.col-xs-hide {
  display: none;
}

.col-xs-show {
  display: block;
}

@media only screen and (min-width: 48em) {
  .col-sm-hide {
    display: none;
  }
  .col-sm-show {
    display: block;
  }
}

@media only screen and (min-width: 64em) {
  .col-md-hide {
    display: none;
  }
  .col-md-show {
    display: block;
  }
}

@media only screen and (min-width: 75em) {
  .col-lg-hide {
    display: none;
  }
  .col-lg-show {
    display: block;
  }
}

span.line {
  display: inline-block;
}

.gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5);
  background-size: 400% 400%;
  -webkit-animation: gradient 30s ease-in-out infinite;
          animation: gradient 30s ease-in-out infinite;
  color: white;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

:root {
  --sidebar-color: var(--color-lightGrey);
  --siderbar-border-color: var(--color-grey);
}

.sidebarmenu {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
  padding: 1rem;
}

.sidebarmenu > .menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.sidebarmenu > header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: end;
      align-items: flex-end;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.sidebarmenu > header {
  width: 100%;
  border-bottom: 1px solid var(--color-grey);
  border-bottom: 1px solid var(--siderbar-border-color);
}

.menu-item {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 1rem;
}

.menu-item > svg {
  color: var(--color-icon-gray);
}

.timer {
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1rem;
  margin: 0;
  margin-top: 0.75rem;
}

.timer span {
  width: 100%;
  display: block;
}

.timer .time {
  font-weight: 500;
  font-size: 1.3rem;
}

.timer-last {
  background-color: var(--color-grey);
}

.timer-next {
  color: white;
  background-color: var(--color-primary);
}

.divider {
  height: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid var(--siderbar-border-color);
  margin: 1rem 0;
}

.sidebar-menu {
  position: fixed;
  overflow-x: hidden;
  width: auto;
  top: 0;
  bottom: 0;
  z-index: 1000;
  -webkit-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
  background-color: var(--sidebar-color);
  border-right: 1px solid var(--siderbar-border-color);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  -webkit-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
  opacity: 0;
}

.sidebar-content {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.header {
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid var(--siderbar-border-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  padding: 0.5rem 0;
}

.header.hidden {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.header-title {
  font-weight: 700;
  font-size: 2.7rem;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  color: black;
  text-decoration: none;
}

.pagestyle {
  background-color: white;
  max-width: 1200px;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 2rem;
}

.productcard {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 1rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.productcard > img {
  height: 100%;
  height: calc(100%);
  width: 100%;
  border-radius: 1rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 0.5rem;
}

.productcard-title-fade {
  position: absolute;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgba(254, 254, 254, 0)),
    color-stop(80%, rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(254, 254, 254, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 1;
}

.productcard-rank {
  font-size: 2.25rem;
}

.productcard-data-row {
  font-size: 1.8rem;
  word-break: keep-all;
}

.productcard-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #707070;
  height: 5rem;
  overflow: hidden;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
}

@media only screen and (min-width: 299px) {
  .productcard-rank {
    font-size: 1rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 524px) {
  .productcard-rank {
    font-size: 1.8rem;
  }

  .productcard-data-row {
    font-size: 1.4rem;
  }
  .productcard-title {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 525px) and (max-width: 1049px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1149px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.8rem;
  }
  .productcard-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1150px) {
  .productcard-rank {
    font-size: 2rem;
  }

  .productcard-data-row {
    font-size: 1.6rem;
  }
  .productcard-title {
    font-size: 1.6rem;
  }
}

.nichecard-container {
  margin: 1rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.nichecard {
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 10px 13px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 1rem;
}

.nichecard-img {
  width: 100%;
  border-radius: 1rem;
}

.nichecard-rank {
  font-size: 2.25rem;
}

.nichecard-data-row {
  font-size: 1.8rem;
  word-break: keep-all;
}

@media only screen and (min-width: 299px) {
  .nichecard-data-row {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 300px) {
  .nichecard-data-row {
    font-size: 1.8rem;
  }
}

.pricetable {
  color: white;
  width: 100%;
}

.pricetable .row {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid white;
  padding: 0.75rem 2rem;
}

.pricetable .row:last-child {
  border-bottom: none;
}

.pricetable .col-xs-10 {
  text-align: start;
}

.pricetable .col-xs-2 {
  text-align: end;
}

.footer {
  color: gray;
  padding: 3rem;
}

.product-viewer {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 1rem;
  margin: 0rem -1.5rem;
}

.callout {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.callout-primary {
  color: white;
  background-color: var(--color-primary);
}

.callout-info {
  color: var(--color-black);
  background-color: var(--color-grey);
}

.callout-warning {
  color: var(--color-warning-text);
  background-color: var(--color-warning);
}

.callout-success {
  color: var(--color-success-text);
  background-color: var(--color-success);
}

.callout-error {
  color: var(--color-error-text);
  background-color: var(--color-error);
}

.callout p {
  margin: 0;
}

.controller-container {
  padding-bottom: 0.5rem;
}

.controller-col {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.controller-switch-container {
  background-color: #e8e8e8;
  font-weight: 500;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
  height: 4rem;
  font-size: 1.6rem;
}

.infiniteloader {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.page-container {
  max-width: 1200px;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2rem;
  word-wrap: break-word;
}

.paymentform-element-input {
  height: 4.1rem;
}

.modal-body {
  z-index: 1100;
  width: 90%;
  max-width: 500px;
  margin: auto;
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 1rem;
  padding: 2rem;
  outline: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  -webkit-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
  opacity: 1;
  z-index: 1099;
  display: -ms-flexbox;
  display: flex;
}

.authmodal-container {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
}

.authmodal-body {
  margin: unset;
  background-color: white;
  width: 100%;
  max-width: unset;
  background-color: white;
  border-radius: 0;
  padding: 2rem;
  padding-top: 3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}

@media only screen and (min-width: 400px) {
  .authmodal-body {
    margin: auto;
    width: 90%;
    max-width: 400px;
    border-radius: 1rem;
    padding: 3rem;
  }
}

