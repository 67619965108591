:root {
  --sidebar-color: var(--color-lightGrey);
  --siderbar-border-color: var(--color-grey);
}

.sidebarmenu {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
}

.sidebarmenu > .menu {
  display: flex;
  flex-direction: row-reverse;
}

.sidebarmenu > header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  box-sizing: border-box;
}

.sidebarmenu > header {
  width: 100%;
  border-bottom: 1px solid var(--siderbar-border-color);
}
